/* eslint-disable vue/multiline-html-element-content-newline */
<template>
  <section id="hero">
    <v-img :src="require('@/assets/hero-1.jpg')" class="white--text"
      gradient="to bottom, rgba(79, 111, 120, .9), rgba(0,0,0,.4)">
      <v-container class="fill-height px-4 py-12">
        <v-responsive class="d-flex align-center mx-auto" height="100%" max-width="700" width="100%">
          <base-subheading weight="regular" class="text-uppercase text-center" :size="
            $vuetify.breakpoint.smAndDown
              ? 'text-body-2'
              : 'text-h5'
          " title="Working to House those with Barriers" />

          <base-heading size="text-h2" class="text-center" title="WELCOME TO APPLY OREGON" weight="medium" />

          <base-body text-class="white--text">
            At Apply Oregon we are enabling a new class of tenants
            by bridging the gap between property managers and
            applicants. We leverage our 30 years of experience in
            rental screening and a powerful technology platform to
            discover barriers that prevent applicants from renting
            and we work to eliminate them.
          </base-body>

          <div class="
                                                        d-flex
                                                        flex-wrap
                                                        justify-center
                                                        align-center
                                                        text-center
                                                    ">
            <base-btn class="mx-auto" bottom height="64" :tile="false" @click="scrollTo('about-our-services')">
              <div class="d-flex flex-column">
                <span>Discover More</span>
                <v-icon class="mt-1">mdi-chevron-down-circle</v-icon>
              </div>
            </base-btn>

            <span class="font-weight-bold ml-1 mr-4 my-4">or</span>

            <base-btn :tile="false" height="64"
              href="https://www.cognitoforms.com/PacificScreening/ApplyOregonLandlordApplication" target="_blank">

              <span class="white--text">Get Started Now</span>
              <v-icon class="mt-1 ml-1">mdi-chevron-right-circle</v-icon>

            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true },
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
  methods: {
    scrollTo(id) {
      this.$vuetify.goTo(`#${id}`, {
        easing: "easeInOutCubic",
        offset: 100,
        duration: 450,
      });
    },
  },
};
</script>
